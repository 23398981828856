@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  --background: #111122;
  --container: #22223366;
  --foreground: #333344;
  --text-primary: #eeeeff;
  --text-secondary: #bbbbcc;
  --text-tertiary: #9999aa;
  --primary: #31e8c3;
  --primary-light: #8cf2de;
  --primary-dark: #12a18455;
  background-color: var(--background);
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  /* background-size: 400% 400%;
  animation: gradient 10s ease infinite; */
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

*::selection {
  background-color: var(--text-secondary);
  color: var(--background);
}
